/*=========================================================================================
  File Name: moduleVideoActions.js
  Description: Video Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.post("API/News/AddNews", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetNews({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("API/News/GetAllNews")
        .then((response) => {
          commit('SET_News', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  SearchNews({ commit },search) {
    return new Promise((resolve, reject) => {
      axios.post("API/News/SearchNews",search)
        .then((response) => {
          commit('SET_News', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem(context, item) {
    return new Promise((resolve, reject) => {
      axios.put("API/News/UpdateNews", item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID(context, itemid) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.get("API/News/GetNewsById?Id="+ itemid)
        .then((response) => {
          debugger;
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteNewsobject({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.delete("API/News/DeleteNews?Id="+item.id)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  UploadImage(context, image) {
    debugger;
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DeleteAttachmenByID(context, itemid) {
    return new Promise((resolve, reject) => {
        debugger
        axios.get("API/Video/DeleteAttachmenByID?HospitalattachmentID=" + itemid)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => { reject(error) })
    })
},

}
