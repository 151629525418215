<template>
  <div>
    <div class="vx-row">
      <vs-button
        type="border"
        class="ml-5"
        icon-pack="feather"
        icon="icon-plus"
        @click="AddOrEditData"
        >{{ $t("AddNew") }}</vs-button
      >
    </div>

    <!-- <div id="data-list-list-view" class="data-list-container">
              <div class="card-container">
                  <div class="card" v-for="item in NewsList" :key="item.id">
                      <div class="card-heading">
                        {{ item.nameAr }}
                      </div>
                       <div class="card-body">
                        {{ item.descriptionAr }}
                       </div>
                         <img  :src="item.imageUrl" alt="News Image" v-if="item.imageUrl" />
                   </div>
                  </div>
              </div> -->

    <div
      style="padding-top: 10px"
      id="data-list-list-view"
      class="data-list-container"
    >
    <div class="card-container flex flex-wrap justify-center items-center">
 <div class="card bg-white shadow-lg rounded-lg p-6 m-4" v-for="item in NewsList" :key="item.id">
      <div class="card-heading text-xl font-bold mb-2">{{ item.nameAr }}</div>
      <div class="card-body text-gray-700 mb-4">{{ item.descriptionAr }}</div>

      <template v-if="item.imageUrl">
 <div class="img-container w-auto h-auto mx-auto flex items-center justify-center">
    <img :src="baseURL + item.imageUrl" class="w-full h-full object-cover rounded-lg" />
 </div>
</template>

      <div class="card-footer text-gray-500">
        <div>
          <span>{{ formatDate(item.createdDate) }}</span>
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <feather-icon
          class="mr-2"
          icon="TrashIcon"
          svgClasses="w-10 h-5 hover:text-primary stroke-current"
          @click.stop="openConfirm(item)"
        />
        <router-link
          :to="{
            name: 'AddNews',
            params: { ID: item.id },
          }"
        >
          <feather-icon
            icon="EditIcon"
            @click.stop="AddOrEditData(item)"
            svgClasses="w-5 h-5 hover:text-primary stroke-current"
          />
        </router-link>
      </div>
 </div>
</div>

    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import moduleNews from "@/store/News/moduleNews.js";

export default {
  data() {
    return {
   //   NewsList: [],
      itemsPerPage: 10,
      baseURL: domain,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    NewsList()
    {
      debugger;
        return this.$store.state.NewsList.News;
    }
  },

  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString(); // Adjust format as needed
    },
    // GetAllNews() {
    //   this.$store.dispatch("NewsList/GetNews")
      // .then((res) => {
      //   debugger;
      //   this.NewsList = res.data.data;
      // });
//  },

    AddOrEditData(data) {
      debugger;
      if (data.id == undefined) {
        data.id = 0;
      }
      this.$router.push(`/News/AddNews/${data.id}`);
    },
    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });

      this.GetAllNews();
    },

    deleteData(data) {
      debugger;
      this.$store.dispatch("NewsList/DeleteNewsobject", data).catch((err) => {
        console.error(err);
      });
      this.$store.dispatch("NewsList/GetNews")
    },
  },
  created() {
    if (!moduleNews.isRegistered) {
      this.$store.registerModule("NewsList", moduleNews);
      moduleNews.isRegistered = true;
    }
    this.$store.dispatch("NewsList/GetNews")

   // this.GetAllNews();
  },
  mounted() {
  //  this.GetAllNews();
    this.isMounted = true;
  },
};
</script>

<style>
.card-heading,
.card-body {
  text-align: right;
  padding: 10px;
}
.card-footer {
  padding: 10px;
}

.card-heading {
  background-color: #a0caca;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: calc(50% - 16px);
  margin-bottom: 20px;
  background-color: whitesmoke;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.image-container {
  padding: 10px;
  height: 100px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 100%) {
  .card {
    width: 100%;
  }
}
</style>
